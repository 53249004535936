const usePrice = (cartItems) => {
    var price = 0;
    var sp_price = 0;
    var service_price = 0;
    var serviceAmount = 0;
    var voucherAmount = 0;
    var updatedTotalAmount = 0;
    var totalSpecialUpfront = 0;
    var totalServiceAmount = 0;
    if (cartItems) {
        cartItems.map((x) => {
            if (!x.retail) {
                if (x.special_price == 0) {
                    price += parseFloat(x.price)
                    sp_price = sp_price + parseFloat(x.price);
                    service_price = parseFloat(service_price) + parseFloat(x.price);
                    totalServiceAmount += parseFloat(x.price);
                    if (parseFloat(x.special_deposit) > 0) {
                        totalSpecialUpfront = parseFloat(totalSpecialUpfront) + parseFloat((parseFloat(x.price) * parseFloat(x.special_deposit) / 100).toFixed(2));
                    } else {
                        serviceAmount += parseFloat(x.price);
                    }
                } else {
                    price += parseFloat(x.price);
                    sp_price = sp_price + parseFloat(x.special_price);
                    service_price = service_price + parseFloat(x.special_price);
                    totalServiceAmount += parseFloat(x.special_price);
                    if (parseFloat(x.special_deposit) > 0) {
                        totalSpecialUpfront = parseFloat(totalSpecialUpfront) + parseFloat((parseFloat(x.special_price) * parseFloat(x.special_deposit) / 100).toFixed(2));
                    } else {
                        serviceAmount += parseFloat(x.special_price);
                    }
                }
            } else {

                updatedTotalAmount = parseFloat(x.retail) * x.qty;
                sp_price += updatedTotalAmount;
                voucherAmount += updatedTotalAmount;

                // console.log(x)
                // sp_price = sp_price + x.retail
            }
        })
    }
    return {
        price,
        sp_price,
        service_price,
        serviceAmount,
        voucherAmount,
        updatedTotalAmount,
        totalSpecialUpfront,
        totalServiceAmount
    }
}

export default usePrice
