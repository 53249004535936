import React, { Fragment, useEffect, useState } from 'react';
// import success from "../../../../dist/images/Payment/payment-success.png";
import logo from "../../../dist/images/logo.svg";
import Apk1 from '../../../dist/images/banner-slider/1.png';
import Apk2 from '../../../dist/images/banner-slider/2.png';
import guestAppImage from '../../../dist/images/app-image/guest_app.PNG';
import businessAppImage from '../../../dist/images/app-image/business_app.PNG';
import { Link, useHistory } from "react-router-dom";
import MetaGenerator from '../widgets/MetaGenerator';
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client';
import { PAGE_VIDEOS } from '../../../gql/Query';

const AppStoreLink = () => {
  const {type} = useParams();
  const history = useHistory();
  const [videoBusiness, setVideoBusiness]= useState({})
  const [videoGuest, setVideoGuest]= useState({})
  const {data:page_videos_business } = useQuery(PAGE_VIDEOS,{
      variables:{
          page: "qr_code_business"
        }
    })
 const {data: page_videos_guest } = useQuery(PAGE_VIDEOS,{
     variables:{
         page: "qr_code_guest"
        }
    })
    useEffect(()=>{
    if(page_videos_business){
        setVideoBusiness(page_videos_business.page_videos)
    }
    if(page_videos_guest){
        setVideoGuest(page_videos_guest.page_videos)
    }
  },[page_videos_business, page_videos_guest])
  return (
    <>
        <MetaGenerator
            title="Apps Link"
        />
        <div className="container app_str_link">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-5 col-md-5 col-sm-7 col-12 mx-auto payment-status">
                    <div className="card venuItem">
                        <div className="card-body text-center">
                            <div className="logo mb-5">
                                <img onClick={()=>history.push('/')} className="" src={logo} alt="brand-logo" />
                            </div>
                            {
                                type === 'business' ?
                                <Fragment>
                                    <div className="stage my-5 text-center stage_video">
                                       <iframe 
                                            width="100%" 
                                            height="100%" 
                                            src={`https://www.youtube.com/embed/${videoBusiness?.video_code}?autoplay=0&mute=0&loop=1&playlist=${videoBusiness?.video_code}&amp;&controls=1`} 
                                            title="YouTube video player"  
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                     <h1  style={{color: '#ebc11a'}} className='mb-4 fw-bold'>Start Your Free 30 Day Trial</h1>
                                     <a href='https://business.chuzeday.com/auth/registration'>
                                        <img src={businessAppImage} style={{borderRadius: '35px'}} alt='business app' height={160} width={160}/>
                                     </a>
                                </Fragment>:
                                <Fragment>
                                    <div className="stage my-5 text-center stage_video">
                                       <iframe 
                                            width="100%" 
                                            height="100%" 
                                            src={`https://www.youtube.com/embed/${videoGuest?.video_code}?autoplay=0&mute=0&loop=1&playlist=${videoGuest?.video_code}&amp;&controls=1`} 
                                            title="YouTube video player"  
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <h1 className="mt-5">Be the Boss of your Own Appointments by Downloading here</h1>
                                    <div>
                                        <a href="https://play.google.com/store/apps/details?id=com.chuzeday.chuzeday" target='_blank'>
                                            <img src={Apk1} width={180} alt="Google play" />
                                        </a>
                                        <a href="https://apps.apple.com/us/app/chuzeday/id1626262034" target='_blank'>
                                            <img src={Apk2} width={180} alt="Apple Store" />
                                        </a>
                                    </div>
                                    <img src={guestAppImage} style={{borderRadius: '35px'}} alt='guest app' height={180} width={180}/>
                                </Fragment>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default AppStoreLink