import React, { useState, useEffect, useContext } from 'react';
import { Form } from "react-bootstrap";
import T1 from '../../../../../dist/images/singleShop/services/arrowdn.png';
import T2 from '../../../../../dist/images/singleShop/services/arrowS.png';
import CartIcon from '../../../../../dist/images/singleShop/services/addCart.png';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { Animated } from "react-animated-css";
import { cartList } from "./ShopService";

const CartBtn = ({ onAdd, content, addBack, person }) => {
    const {cartItems} = useContext(cartList);
    const [clickValue, setClickValue] = useState(true);
    //console.log("onAdd, content, addBack, person", onAdd, content, addBack, person)
    var x = 0;
    const ServiceAdd = () => {
        if (person === "") {
            toast.error("Please select a person to add this voucher to the cart!")
        } else {
            x = x + 1
            onAdd(content, x, person);
            toast.success("Added to cart!");
        }
    }
    useEffect(() => {
        if (addBack) {
            if (content.id === addBack) {
                setClickValue(true);
            }
        }
        if (cartItems.length > 0) {
            for (const item of cartItems) {
                if (item.id === content.id) {
                    setClickValue(false);
                }
            }
        }
        if (cartItems.length === 0) {
            setClickValue(true);
        }
    }, [addBack, cartItems, content.id])
    // console.log("qty", qty)
    return (
        <>
            <span className={clickValue ? "d-inline-block" : "d-none"}>
                <span className="CartIcon" onClick={() => { ServiceAdd() }} >
                    <img src={CartIcon} alt="icon" />
                </span>
            </span>
        </>
    )
}
export default function Vouchers({ content, onAdd, addBack, qtyValue, profileName, personVal, currency, person, setPerson }) {
    const history = useHistory();
    const guest_token = localStorage.getItem('guest_token')
    // if (content) {
    //     console.log("content", content)
    // }
    // if (profileName) {
    //     console.log("profileName children", profileName)
    // }
    const [show, setShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const [services, setServices] = useState([]);
    const [visible2, setVisible2] = useState(false);
    const [showField, setShowField] = useState(false);
    const selectPerson = (val) => {
        if (!guest_token) {
            toast.info("Please login first!");
            history.push("/guest-login")
        } else {
            if (val === "self") {
                setShowField(false)
                // console.log("name", profileName)
                setPerson(profileName)
            } else {
                setPerson("")
                setShowField(true)
                setVisible2(true);
            }
        }
    }

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        if (profileName) {
            console.log(profileName)
            setPerson(profileName)
        }
        if (content.services_included) {
            //console.log("content.services_included", content.services_included)
            setServices(content.services_included);
            
        }
    }, [profileName, content.services_included]);

    return (
        <section id="shopServiceItem" className='single-voucher col-md-12'>
            <div className="category row">
                <div className="col-6"
                    onClick={() => {
                        setVisible(!visible);
                        setTimeout(() => {
                            setShow(!show);
                        }, 500)
                    }}
                >
                    <div className='d-flex'>
                        {show ? <img src={T1} alt="icon" /> : <img src={T2} alt="icon" />}
                        <h5>{content?.name}</h5>
                    </div>
                </div>
                <div className="col-3">
                    <div className="price">
                        <span>{`${currency}${content.retail}`}</span>
                    </div>
                </div>
                <div className="col-3">
                    <div className="price">
                        <span>{content.valid_for}</span>
                    </div>
                </div>
            </div>
            {show &&
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={visible} animationInDelay={100} animationOutDelay={10000}>
                    <div className="row mx-0 mb-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-md-10 col-9 mx-auto">
                                    <h3 className='chuzy-bg text-white p-4 rounded-3 d-flex justify-content-between'>{content?.title}
                                        <CartBtn onAdd={onAdd} content={content} addBack={addBack} qtyValue={qtyValue} person={person} personVal={personVal} />
                                    </h3>
                                    <div className='row'>
                                        {
                                            content?.note &&
                                            <div className="col-12">
                                                <p className='shadow-sm bg-light p-2 my-2'>{content?.note}</p>
                                            </div>
                                        }
                                        <div className="col-md-7">
                                            <ul className="name text-capitalize">
                                                {
                                                    services?.length > 0 ?
                                                        services?.map((item) => (
                                                            <li className='d-flex' key={item.label}>
                                                                <i className="far fa-check-circle mt-2 me-2"></i>
                                                                <span>{item.label}</span>
                                                            </li>
                                                        )) :
                                                        <li><i className="far fa-check-circle mt-2 me-2"></i>
                                                            <span>All Services</span>
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                        <div className="col-md-5 chuez-modal">
                                            <div className="person-select p-3 font-chzday rounded">
                                                <div>
                                                    <input className='form-check-input me-2' name="person"
                                                        type="radio"
                                                        checked={(guest_token && (person === profileName)) ? true : false}
                                                        id="self" onChange={() => { selectPerson("self") }}
                                                    />
                                                    <label htmlFor="self">For myself</label>
                                                </div>
                                                <div>
                                                    <input className='form-check-input me-2' name="person"
                                                        type="radio"
                                                        id="Others"
                                                        onChange={() => { selectPerson("Others") }}
                                                    />
                                                    <label htmlFor="Others">For someone else</label>
                                                </div>
                                                {showField &&
                                                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={visible2} animationInDelay={100} animationOutDelay={10000}>
                                                        <Form.Control
                                                            className='p-2 text-capitalize'
                                                            type="text"
                                                            defaultValue={""}
                                                            placeholder="Ex: Caroline Smith"
                                                            onChange={(e) => { setPerson(e.target.value) }}
                                                        />
                                                    </Animated>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            }
        </section>
    );
}
