import React from 'react';
import moment from "moment";
import demoService from '../../../../dist/images/Services/customer-banner.png';
import { imageBaseURL } from '../../system/utility';

const AptLeft = ({ item, toggleTab, toggleState }) => {
    const imageURL = `${imageBaseURL}/uploads/businessPhoto/`;
    const maxLength = 60;
    return (
        <div onClick={() => toggleTab(item.id)} className="nav-pills">
            <div className={item.id === toggleState ? "appn-tab d-flex align-items-center nav-link bg-white" : "appn-tab d-flex align-items-center nav-link"}>
                <img src={item?.business_info?.thumbnail ? `${imageURL}${item?.business_info?.thumbnail}` : demoService} alt={item.name} />
                <div className="info">
                    <time className="text-muted text-capitalize h4"> {moment.unix(item?.date).fromNow()}</time>
                    <p className="text-muted text-capitalize h4">{moment.unix(item?.date).utcOffset('+0000').format('llll')}</p>
                    <h4>{item?.business_info?.name}</h4>
                    {
                        item?.business_info?.description?.length > maxLength ?
                            <p>{item?.business_info?.description ? `${item?.business_info?.description.substring(0, maxLength)}...` : ""}</p> :
                            <p>{item?.business_info?.description}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default AptLeft
