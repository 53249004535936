import { gql } from "@apollo/client";

export const SINGLE_USER = gql`
query user($id: ID){
  user(id: $id){
    id
    name
    email
    user_type
  }
}
`;

export const ALL_USER = gql`
query users($page: Int, $first: Int){
    users(page: $page, first: $first){
        data{
            id
            name
            email
            user_type
        }
        paginatorInfo{
            count
            total
        }
    }
}
`;
export const PROFILE_INFO = gql`
query {
  profileInformation {
    id
    first_name
    last_name
    mobile
    email
    photo
    user_type
    social_login
    identify
    google_calendar
    client_detail{
      id
      additional_mobile
      dob
      gender
      address
      email_notification 
      suite
      country
      eir_code
      favorite_business{
        id
        business_id
      }
    }
  }
}
`;

// export const COUNTRIES = gql`
// query{
//   countries{
//     id
//     name
//   }
// }
// `;

export const BUSINESS_TYPE = gql`
query{
  business_types{
    id
    name
    image
  }
}
`;

export const SERVICE_LIST = gql`
query service_categories(
    $type: String
    $business_id: Int
      ){
    service_categories(
      type: $type
      business_id: $business_id
      ){
          id
          name
          services{
            id
            description
            is_group
            is_course
            special_deposit
            service_schedules{
              date_time
            }
            service_group{
              service_id
              is_group
              is_course
              group_type
              client_per_class
              session_per_course
              start_date
              enroll_date
              occurrences
              frequency
              schedule_type
              schedules{
                day
                date
                time
                sameOthers
              }
            }
            service_pricings{
              id
              service_id
              service_name
              duration
              price
              special_price
            }
        }
      }
}
`;

export const SEARCH_SERVICES = gql`
query search_business(
  $sort: String
  $gender: String
  $business_type: String
  $lat: String!
  $lng: String!
  $distance: Int!
){
    search_business(
      sort: $sort
      gender: $gender
      business_type: $business_type
      lat:$lat
      lng: $lng
      distance: $distance
      ){
        id
        name
        size
        slug
        location
        distance
        slider
        thumbnail
        social_links{
          facebook
          instagram
          linkedin
          tiktok
        }
        rating
  }
}
`;
export const FEATURED_BUSINESS = gql`
query featured_businesses(
  $latitude: String
  $longitude: String
  $country: String
){
    featured_businesses(
      latitude: $latitude
      longitude: $longitude
      country: $country
    ){
        id
        name
        slug
        location
        thumbnail
        rating
    }
}
`;
export const SINGLE_SHOP = gql`
query single_business(
  $slug: String!
){
    single_business(
     slug: $slug
      ){
        business{
          id
          name
          about
          country
          location
          thumbnail
          slider
          latitude
          longitude
          website
          description
          rating
          number_of_ratings
          social_links{
            facebook
            instagram
            linkedin
            tiktok
          }
          upfront_amount
          cancellation{
            value
          }
          video_vetting
          slot_duration
          work_hours{
            id
            duration
            weekday
            s_time
            e_time
          }
        }
        close_weekday{
          weekday
        }
        close_date{
          date
        }
        business_vouchers{
          id
          name
          value
          retail
          title
          description
          note
          valid_for
          limit_number_of_sales
          total_sale
          services_included{
            value
            label
          }
        }
        business_tax
        block_date{
          date
        }
  }
  me{
    first_name
    last_name
  }
}
`;
export const SERVICE_CATEGORIES = gql`
query service_list(
  $business_id: Int!
){
   service_list(
     business_id: $business_id
      ){
        name
        service_category_info{
        id
        name
        services{
          id
          name
          staffs
          service_pricings{
            id
            service_id
            service_name
            duration
            price_type
            price
            pricing_name
            special_price
            staffs
          }
        }
    }
  }
}
`;
export const MY_FAVOURITES = gql`
query my_favorite_business(
  $count: Int!
  $page: Int!
){
   my_favorite_business(
      count: $count
      page: $page
      ){
        data{
        id
        businesses{
          id
          thumbnail
          name
          location
          slug
          rating
          number_of_ratings
        }
      }
      paginatorInfo{
        total
      }
    }
}
`;

export const OPINIONS = gql`
query {
  opinions {
    id
    name
    photo
    description
    position
  }
}
`;
export const MY_APPOINTMENTS = gql`
query appointments(
  $date_range: String
  $keyword: String
  $type: String
  $count: Int
  $page: Int
){
   appointments(
        date_range: $date_range
        keyword: $keyword
        type: $type
        count: $count
        page: $page
      ){
        data{
          id
          business_id
          status
          date
          discount
          note
          sub_total
          total_amount
          room_id
          sale_id
          status
          review_status
          payment_status
          upfront_percent
          user_id
          payment
          business_info{
            id
            name
            country
            slug
            location
            thumbnail
            description
            upfront_amount
            weekdays{
              weekday
            }
            block_dates{
              date
            }
          }
          appointment_detail{
            id
            chair
            duration
            chair
            ser_pricing_id
            time
            appt_id
            is_group
            price
            special_price
            is_course
            service_pricing{
              id
              price
              service_name
              special_price
              duration
            }
          }
        }
        paginatorInfo{
          total
        }
    }
    me{
      first_name
      last_name
    }
}
`;

export const TIME_SLOT = gql`
query business_time_slot(
    $business_id:        Int!
    $date:               String!
    $service_pricing_id: Int!
){
    business_time_slot(
      business_id:        $business_id
      date:               $date
      service_pricing_id: $service_pricing_id
      ){
        date_slots{
        s_date
      }
      time_slots{
       s_time
    }
  }
}
`;

export const MY_VOUCHERS = gql`
query my_voucher(
  $count: Int!
  $page: Int!
){
   my_voucher(
      count: $count
      page: $page
      ){
        data{
          id
          business_id
          buyer_id
          owner
          voucher_code
          voucher_detail{
            retail
            value
            color
            services_included{
              value
              label
            }
          }
          purchase_date
          value
          redeemed
          remaining
          expiry_date
          message
          business_info{
            name
            country
          }
        }
        paginatorInfo{
          total
        }
    }
}
`;
export const CLIENT_REPORT = gql`
query{
  clientReport{
    total_appt
    total_voucher
    total_favourite
  }
}
`;
export const INVOICE = gql`
query invoiceDetail(
   $sale_id: Int!
   $appt_id: Int!
){
    invoiceDetail(
      sale_id: $sale_id
      appt_id: $appt_id
      ){
        id
        inv_no
        inv_pre
        buyer_id
        buyer_name
        business_id
        total_amount
        sub_total
        discount
        s_discount
        payment_status
        payment_type
        payment_amount
        created_at
        due_amount
        buyer_info{
          id
          mobile
          email
          address
        }
        business_info{
          name
          thumbnail
          country
          vat_number
        }
        sale_detail{
          id
          business_id
          sale_id
          product_id
          product_name
          product_qty
          unit_price
          discount
          time
          duration
          expiry_date
          voucher_code
        }
      }
}
`;
export const RATING_FILTER = gql`
query business_reviews(
    $business_id: Int!
){
    business_reviews(
      business_id : $business_id
      ){
        rating{
          rating_level
          number_of_rating
        }
        lists{
          id
          business_id
          user_id
          appt_id
          rating
          comment
          created_at
          user_info{
            first_name
            last_name
          }
        }
      }
}
`;
export const VOUCHER_QUERY = gql`
query myVoucher(
    $voucher_code: String!
    $selling_service: [ServiceItem!]!
    $guest_id: Int!
    $grand_total: String!
    $discount: String!
    $business_id: Int!
){
    myVoucher(
      voucher_code: $voucher_code
      selling_service: $selling_service
      grand_total: $grand_total
      guest_id: $guest_id
      discount: $discount
      business_id: $business_id
    ){
        value
        message
        status
    }
}
`;
export const VOUCHER_LIST = gql`
query business_wise_voucher(
    $business_id: Int!
){
    business_wise_voucher(
     business_id: $business_id
      ){
        id
        voucher_code
      }
}
`;

export const HOME_SLIDER = gql`
query{
  sliders{
    bannerId: id
    title
    image
    youtube_url
    status
  }
}
`;

export const GUEST_STATUS = gql`
query guest_status(
    $business_id: Int!
){
    guest_status(
     business_id: $business_id
      ){
        status
        block
      }
}
`;
export const MY_NOTIFICATION = gql`
query{
   myNotification{
    data{
      id
      sender_id
      message
      status
      title
      ref_id
      recipient_id
      target_url
      business_slug
      services
      created_at
    }
    count{
      new_notification
      total_notification
    }
  }
}
`;
export const NOTIFICATION_SEEN = gql`
query{
   myNotificationSeen{
      status
      message
  }
}
`;
export const PARTNER_FEATURE = gql`
query{
  partner_features{
    id
    title
    slug
    image
  }
}
`;
export const SINGLE_PARTNER_FEATURE = gql`
query partner_feature(
    $slug: String!
){
    partner_feature(
     slug: $slug
      ){
        id
        title
        slug
        sliders
        image
        description
      }
}
`;
export const WEBSITE_VISIT_SLIDER = gql`
query website_visit_sliders(
    $type: String!
){
    website_visit_sliders(
     type: $type
      ){
        id
        image
        title
      }
}
`;
export const BLOGS = gql`
query {
  blogs {
    id
    title
    image
    description
    slug
  }
}
`;
export const BLOG = gql`
query blog(
    $slug: String!
){
    blog(
     slug: $slug
      ){
        blog{
          title
          description
          image
        }
        related_blogs{
          id 
          title 
          slug
        }
      }
}
`;

export const FAQS = gql`
query faq_categories($keyword: String){
  faq_categories(keyword: $keyword){
    id
    name
    faqs{
      id
      question
      answer
    }
  }
}`;

export const PROFILE_CONTACT = gql`
query {
  profileInformation {
    first_name
    last_name
    mobile
    email
  }
}
`;
export const NOTIFICATION_BUSINESS = gql`
query single_business(
  $slug: String!
){
    single_business(
     slug: $slug
      ){
        business{
          id
          name
          weekdays{
            weekday
          }
        }
        close_weekday{
          weekday
        }
        close_date{
          date
        }
        block_date{
          date
        }
      }
}
`;

export const PAGES = gql`
query pages(
    $slug: String
){
    pages(
     slug: $slug
    ){
        id
        title
        body
    }
}`;
export const OFFBOARDING_QUESTIONS = gql`
query{
  offboarding_question{
    title
    questions
    description
  }
}
`;
export const AUTH_CHECK = gql`
query{
  profileInformation{
    email
  }
}
`;

export const PAGE_VIDEOS = gql`
query page_videos(
    $page: String!
){
    page_videos(
     page: $page
    ){
        video_code
        page
    }
}`;
//checking time slot
export const TIME_SLOT_AVAILABILITY = gql`
    query timeslot_availability(
       $services: [BookingServiceInput!]!, $business_id: Int!
    ){
    timeslot_availability(
        services: $services,
        business_id: $business_id
    ){
        status
        data
        message
    }
}`;

// group_service_available_occurrences
export const AVAIABLE_OCCURENCES = gql`
    query group_service_available_occurrences(
        $business_id: Int!
        $service_id: Int!
        $date: String!
        $time: String!
    ){
    group_service_available_occurrences(
        business_id: $business_id
        service_id: $service_id
        date: $date
        time: $time
    ){
        available_occurrences
    }
}`;