import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import Logo from '../../../dist/images/Login/logo.svg';
import arrow from '../../../dist/images/Login/colourLeft.png';
import { Form, Button } from 'react-bootstrap';
import LoginBottom from "./LoginBottom";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import MetaGenerator from '../widgets/MetaGenerator';

//API
import { AUTH_LOGIN, SYSTEM_LOG } from '../../../gql/Mutation';
import { getDeviceInfo } from '../widgets/logUtilities';
import { print } from 'graphql';

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Email should have correct format")
        .required("Email is a required"),
    password: yup
        .string()
        .required('Please Enter your password')
})

const LoginWithMail = () => {
    const [display, setDisplay] = useState(true);
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const togglePass = () => {
        setIsPasswordShown(!isPasswordShown);
    }
    const { register, formState: { errors }, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
        const [system_log] = useMutation(SYSTEM_LOG,{
        onError(err) {
            toast.error(err.graphQLErrors[0].extensions.reason);
        }
    });
    const [login] = useMutation(AUTH_LOGIN, {
        onError(err) {
            setDisplay(true);
            toast.error(err.graphQLErrors[0].extensions.reason);
        }
    });
    const AUTH_LOGIN_STRING = print(AUTH_LOGIN);
    const guest_token = localStorage.getItem('guest_token')
    if (guest_token) {
        history.push("/");
    }

    const onSubmit = data => {
        setDisplay(false);
        const { email, password } = data;
        const systemLogPayload = {
            api: AUTH_LOGIN_STRING,
            type: 'guest-login-error',
            user: guest_token?? "",
            body: JSON.stringify(email),
            response: "",
            exception: "",
            source: 'web-guest',
            version: '1.1.0',
            priority: 'high',
            device: getDeviceInfo(),
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!(re.test(email))) {
            document.querySelector(`#email`).focus();
            document.querySelector(`#email`).scrollIntoView({ behavior: 'smooth', block: 'end' });
            toast.error("Please enter a valid Email Address!");
        } else {
            login({
                variables: {
                    username: email,
                    password: password,
                }
            }).then(({ data }) => {
                // console.log(data.login.access_token);
                if (data) {
                    if (data.login?.user?.user_type === 2) {
                        setDisplay(true);
                        toast.success("Login successful!");
                        if (data.login) {
                            localStorage.setItem('guest_token', data.login.access_token);
                            localStorage.setItem('cz_type', data.login.user.user_type);
                            localStorage.setItem('user_id', data.login.user.id);
                        }
                        history.push('/profile/my-profile')
                    }
                    if (data.login?.user?.user_type === 1) {
                        toast.error("Please login with your guest account!");
                        setDisplay(true);
                        system_log({
                            variables: {...systemLogPayload, response: JSON.stringify(data.login?.user)}
                        });
                    }
                }
            }).catch(e => {
                console.log(e);
                toast.error("Internal server error!");
                setDisplay(true);
                system_log({
                    variables: {...systemLogPayload, exception: JSON.stringify(e)}
                });
            })
        }
    }

    return (
        <>
            <MetaGenerator
                title="Login with email"
            />
            <section id="LoginMail">
                <div className="mailLoginWrap">
                    <div className="LoginInner">
                        <div className="LogoWrap">
                            <span onClick={goToPreviousPath} className="BackHome">
                                <img src={arrow} alt="icon" />
                            </span>
                            <Link to="/home" className="Logo">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <span></span>
                        </div>
                        <div className="LoginTop">
                            <span className="title">Log in with email</span>
                            <Form onSubmit={handleSubmit(onSubmit)} className="loginMailForm" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    handleSubmit(onSubmit);
                                }
                            }}
                                autocomplete="off"
                            >
                                <div className="formInput">
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Email address" id="email" name="email" {...register('email')}
                                            error={!!errors.email}
                                        />
                                        <span className="form-error">{errors?.email?.message}</span>
                                    </Form.Group>
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Password</Form.Label>
                                        <div className="passWrap">
                                            <Form.Control type={isPasswordShown ? "text" : "password"} placeholder="Password" name="password" {...register('password')}
                                                error={!!errors.password}
                                            />
                                            <i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"} passwordIcon`}
                                                onClick={togglePass}
                                            />
                                        </div>
                                        <span className="form-error">{errors?.password?.message}</span>
                                    </Form.Group>
                                    <Link to="/forget-password" className="Forgotpass">
                                        Forgot Password?
                                    </Link>
                                </div>
                                {
                                    display &&
                                    <Button className="signUpBtn" type="submit">
                                        Log In
                                    </Button>
                                }
                            </Form>
                            <div className="register">
                                <span>Don't have an account?</span>
                                <Link to="/signup" className="RegisterBtn">
                                    Register Now
                                </Link>
                            </div>
                        </div>
                        <LoginBottom />
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginWithMail;