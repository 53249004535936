import React from 'react';
import Header from '../Header/Header';
import Service from '../Home/components/Service/Service';
import Venu from '../Home/components/Venues/Venue';
import Feature from '../Home/components/Feature/Feature';
import Testimonial from '../Home/components/Testimonial/Testimonial';
import Footer from '../Footer/Footer';
import MetaGenerator from '../widgets/MetaGenerator';
import DownloadApp from './components/DownloadApp/DownloadApp';
import RevolutAffiliateBanner from './revolutAffiliateBanner/RevolutAffiliateBanner';
import UpdateNotice from './components/UpdateNotice';

const Home = () => {
    return (
        <>
            <MetaGenerator
                title="Chuzeday"
            />
            <Header />
            {/* <UpdateNotice /> */}
            <DownloadApp />
            {/* <Service idName="service" /> */}
            {/* <Venu /> */}
            {/* <Feature /> */}
            <Testimonial />
            {/* <RevolutAffiliateBanner layout={"homePage"} /> */}
            <Footer />
        </>
    );
};

export default Home;