import {useMemo} from 'react'

const useCurrentCartData = (currentCartItem) => {
  const currentCart = useMemo(() => {
        const servicePriceIDList = [];
        if (currentCartItem) {
            if (currentCartItem.__typename === "ServicePricing") {
                servicePriceIDList.push(currentCartItem.id);
            } 
            
        }
        const totalIDList = {
            service: servicePriceIDList,
        };
        return JSON.stringify(totalIDList);
    }, [currentCartItem]);

    return currentCart;
}

export default useCurrentCartData