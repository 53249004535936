import { useRef, useEffect, useState } from "react";
import RevolutCheckout from "@revolut/checkout";
import { Form } from "react-bootstrap";
import Revolut from '../../../../../dist/images/PayGateways/revolut.png';
import { toast } from 'react-toastify';
import { getData } from "country-list";
import GetIP from "../../../widgets/GetIP";
import { useMutation } from '@apollo/client';
import { Spinner } from "react-bootstrap";
import baseUrl from "../../../../baseUrl";

//API
import { SYSTEM_LOG, REVOLUT_PAY_CONFIRM } from "../../../../../gql/Mutation";
import { getDeviceInfo } from "../../../widgets/logUtilities";

function RevolutPayment({ order, amount, saleId, appointId, revolutId }) {
    //console.log("order, amount, saleId, revolutId", order, amount, saleId, revolutId)
    const [code, setCode] = useState("IE");
    const rcRef = useRef(null);
    const cardElementRef = useRef(null);
    const [cardErrors, setCardErrors] = useState([]);
    const [loader, setLoader] = useState(false);
    const userLocation = GetIP();
    const guest_token = localStorage.getItem('guest_token');
    const [revolutPaymentConfirmation] = useMutation(REVOLUT_PAY_CONFIRM);
    //------------payment log data---------------------
    const [system_log] = useMutation(SYSTEM_LOG,{
        onError(err) {
            toast.error(err.graphQLErrors[0].extensions.reason);
            setLoader(false);
        }
    })

    useEffect(() => {
        if (!order) return;
        // const mode = window.location.host.includes("chuzeday.com") ? "prod" : "sandbox";
        const mode = "sandbox";

        // const mode = "prod";
        RevolutCheckout(order, mode).then(RC => {
            rcRef.current = RC.createCardField({
                target: cardElementRef.current,
                hidePostcodeField: true,
                styles: {
                    default: {
                        color: "#212529",
                        "::placeholder": {
                            color: "#666"
                        }
                    },
                    autofilled: {
                        color: "#212529"
                    }
                },
                onValidation(errors) {
                    setCardErrors(errors);
                    if (errors.length > 0) {
                        setLoader(false);
                    }
                },
                onSuccess() {
                    // console.log("revolutPaymentConfirmation")
                    // console.log("saleId, revolutId", saleId, revolutId)
                    const revolutPayload = {
                        sale_id: saleId,
                        order_id: revolutId,
                        appt_id: appointId
                    }
                    revolutPaymentConfirmation({
                        variables: revolutPayload
                    }).then(({ data }) => {
                        // console.log("revolut payment res",data)
                        if (data.revolutPaymentConfirmation) {
                            setLoader(false);
                             const systemLogPayload = {
                                api: 'revolut_payment',
                                user: guest_token,
                                body: JSON.stringify(revolutPayload),
                                response: "",
                                exception: "",
                                source: 'web-guest',
                                version: '1.1.0',
                                priority: 'high',
                                device: getDeviceInfo(),
                            }
                            if (data.revolutPaymentConfirmation.status === 1) {
                                system_log({
                                    variables: {...systemLogPayload, response: JSON.stringify(data.revolutPaymentConfirmation)}
                                });
                                window.location.replace(data.revolutPaymentConfirmation.payment_url);
                                toast.success(data.revolutPaymentConfirmation.message)
                            } else {
                                system_log({
                                    variables: {...systemLogPayload, response: JSON.stringify(data.revolutPaymentConfirmation)}
                                })
                                window.location.replace(data.revolutPaymentConfirmation.payment_url);
                                toast.error(data.revolutPaymentConfirmation.message);
                                // history.push("/payment-fail");
                            }
                        }
                    }).catch(e => {
                        console.log("error", e);
                        const systemLogPayload = {
                            api: 'revolut_payment',
                            user: guest_token,
                            body: JSON.stringify(revolutPayload),
                            response: "",
                            exception: "",
                            source: 'web-guest',
                            version: '1.1.0',
                            priority: 'high',
                            device: getDeviceInfo(),
                        }
                        system_log({
                            variables: {...systemLogPayload, exception: JSON.stringify(e)}
                        })
                        toast.error("Internal server error!");
                        window.location.replace(`${baseUrl}/payment-fail`);
                        setLoader(false);
                    })
                },
                onCancel() {
                    renewOrder(order);
                    setLoader(false);
                }
            });
        });

        return () => {
            rcRef?.current?.destroy();
        };
    }, [order]);

    //-----------------country code from IP address-----------------------
    useEffect(() => {
        if (userLocation) {
            setCode(userLocation.country_code)
        }
    }, [userLocation])

    async function handleFormSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        console.log(data.get("name"), data.get("email"), data.get("region"), data.get("city"), data.get("streetLine1"), data.get("streetLine2"), data.get("postcode"));
        if (data.get("email") === "") {
            toast.error("Please enter your email address!");
        } else if (data.get("region") === "") {
            toast.error("Please enter your region!");
        } else if (data.get("city") === "") {
            toast.error("Please enter your city!");
        } else if (data.get("streetLine1") === "") {
            toast.error("Please enter your Address line 1!");
        } else if (data.get("postcode") === "") {
            toast.error("Please enter your post code!");
        } else {
            setLoader(true);
            rcRef.current.submit({
                name: data.get("name"),
                email: data.get("email"),
                billingAddress: {
                    countryCode: data.get("countryCode"),
                    region: data.get("region"),
                    city: data.get("city"),
                    streetLine1: data.get("streetLine1"),
                    streetLine2: data.get("streetLine2"),
                    postcode: data.get("postcode")
                }
            });
        }
    }

    async function renewOrder(id) {
        const response = await fetch(`/api/orders/${id}/renew`, { method: "POST" });
        const order = await response.json();
        //Router.replace(`/?order=${order.id}`);
        console.log("cancel", order);
    }

    if (order === null) {
        return (
            <>
                <h2>Checkout</h2>
                <h3>Order not found</h3>
            </>
        );
    }

    return (
        <div className="mailLoginWrap">
            <div className="LoginTop p-2">
                <div className="d-flex justify-content-center">
                    <img className="revolut" src={Revolut} alt="Revolut" />
                </div>
                <Form onSubmit={handleFormSubmit} autocomplete="off">
                    <span className="title">Contact</span>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Full Name*</Form.Label>
                                <Form.Control
                                    name="name"
                                    autoComplete="name"
                                    placeholder="Enter your full name"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    name="email"
                                    autoComplete="email"
                                    placeholder="Email"
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <span className="title">Billing</span>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Card Number*</Form.Label>
                                <div className="form-field" ref={cardElementRef} />
                                <div className="form-control" ref={cardElementRef}>Card*</div>
                                <p className="text-danger">
                                    {cardErrors.map(
                                        error =>
                                            // you can use `error.type` to customise message
                                            error.message
                                    )}
                                </p>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Country*</Form.Label>
                                <select
                                    className="form-control"
                                    name="countryCode"
                                    value={code}
                                    onChange={(e) => { setCode(e.target.value) }}
                                    required
                                >
                                    {getData().map(country => (
                                        <option key={country.code} value={country.code}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Region*</Form.Label>
                                <Form.Control
                                    name="region"
                                    autoComplete="address-level1"
                                    placeholder="Region"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>City*</Form.Label>
                                <Form.Control
                                    name="city"
                                    autoComplete="address-level2"
                                    placeholder="City"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Address line 1*</Form.Label>
                                <Form.Control
                                    name="streetLine1"
                                    autoComplete="address-line1"
                                    placeholder="Street, house number"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Address line 2</Form.Label>
                                <Form.Control
                                    name="streetLine2"
                                    autoComplete="address-line2"
                                    placeholder="Appartment, building"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Postal code*</Form.Label>
                                <Form.Control
                                    name="postcode"
                                    autoComplete="postal-code"
                                    placeholder="Postal code"
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button className="primaryBtn btn d-flex" disabled={loader}>
                            Pay €{amount.toFixed(2)}
                            {
                                loader &&
                                <div className="ms-2"><Spinner animation="border" /></div>
                            }
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
}
export default RevolutPayment;
