import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Email from '../../../../../dist/images/Login/email.png';
import Google from '../../../../../dist/images/Login/google.png';
import Facebook from '../../../../../dist/images/Login/facebook.png';
import Apple from '../../../../../dist/images/Login/apple.png';
import arrow from '../../../../../dist/images/Login/colourLeft.png';
import backarrow from '../../../../../dist/images/Login/backLogin.png';
import SelectedService from "./SelectedService"
import { useForm } from 'react-hook-form';
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Logo from '../../../../../dist/images/Login/logo.svg';
import * as yup from 'yup';
import GetIP from "../../../widgets/GetIP";

// firebase signin
import firebase from "@firebase/app";
import "firebase/auth";
import firebaseConfig from '../../../Login/FirebaseConfig/firebase.config';

//API
import { SOCIAL_LOGIN, AUTH_LOGIN, GUEST_REGISTRATION, SOCIAL_REGISTER, FORGET_PASSWORD } from "../../../../../gql/Mutation";
import { PROFILE_INFO } from "../../../../../gql/Query";

const schemaLogin = yup.object().shape({
    email: yup
        .string()
        .email("Email should have correct format")
        .required("Email is a required"),
    password: yup
        .string()
        .required('Please Enter your password')
})
const schemaReg = yup.object().shape({
    firstName: yup
        .string()
        .required("First name is required"),
    lastName: yup
        .string()
        .required("Last name is required"),
    email: yup
        .string()
        .email("Email should have correct format")
        .required("Email is required"),
    password: yup
        .string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/])[A-Za-z\d@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and one special case Character"
        ),
    confirm_password: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: yup.bool().oneOf([true], 'Accept Terms & privacy policy')
})

const BookingLogin = ({ getAuth, goBack, date, time, cartItems, upfront, totalVPrice, totalSPrice, currency, guestStatus, videoVetting, setName }) => {
    const [baseLogin, setBaseLogin] = useState(true);
    const [forPass, setForPass] = useState(false);
    const [emailSignin, setEmailSignin] = useState(false);
    const [emailSignup, setEmailSignup] = useState(false);

    // console.log("emailSignin, emailSignup", emailSignin, emailSignup)
    const { refetch } = useQuery(PROFILE_INFO)
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
    }
    const [socialLogin] = useMutation(SOCIAL_LOGIN,
        {
            onError(err) {
                toast.error(err.graphQLErrors[0].extensions.reason);
            }
        }
    );
    const socialRegisterSubmit = (result, provider) => {
        let first, last
        if (provider === "apple") {
            [first, last] = result.user.displayName.split(' ');
        }
        socialRegister({
            variables: {
                first_name: provider === "google" ? result?.additionalUserInfo?.profile?.given_name : (provider === "apple" ? first : result?.additionalUserInfo?.profile?.first_name),
                last_name: provider === "google" ? result?.additionalUserInfo?.profile?.family_name : (provider === "apple" ? last : result?.additionalUserInfo?.profile?.last_name),
                mobile: "",
                email: provider === "apple" ? result.user?.email : result?.additionalUserInfo?.profile?.email,
                password: provider === "apple" ? result?.credential?.idToken : result?.credential?.accessToken,
                provider: provider,
                provider_id: provider === "apple" ? result.user.providerData[0].uid : result?.additionalUserInfo?.profile?.id
            }
        }).then(({ data }) => {
            // console.log(data.clientRegister.message);
            if (data) {
                toast.success("Registration successful!");
                socialLogin({
                    variables: {
                        provider: provider,
                        token: provider === "apple" ? result?.credential?.idToken : result?.credential?.accessToken,
                    }
                }).then(({ data }) => {
                    // you can do something with the response here
                    //console.log(data);
                    if (data) {
                        // toast.success("Login successful!");
                        if (data?.socialLogin?.user?.user_type === 2) {
                            localStorage.setItem('guest_token', data.socialLogin.access_token);
                            localStorage.setItem('cz_type', data.socialLogin.user.user_type);
                            localStorage.setItem('user_id', data.socialLogin.user.id);
                            setName(`${data.socialLogin.user.first_name} ${data.socialLogin.user.last_name}`);
                            getAuth(true);
                            setEmailSignin(false);
                            refetch();
                        } else {
                            toast.info("Please login with your guest account!")
                        }
                    }
                }).catch(e => {
                    console.log(JSON.stringify(e));
                })
            }
        }).catch(errors => {
            // console.log("error", errors);
            if (errors) {
                if (errors?.graphQLErrors[0].extensions.validation.email) {
                    // console.log(errors.graphQLErrors[0].extensions.validation.email[0])
                    if (errors?.graphQLErrors[0].extensions.validation.email[0] === "The email has already been taken.") {
                        socialLogin({
                            variables: {
                                provider: provider,
                                token: provider === "apple" ? result?.credential?.idToken : result?.credential?.accessToken,
                            }
                        }).then((data) => {
                            // you can do something with the response here
                            console.log(data?.data?.socialLogin?.access_token);
                            if (data?.data) {
                                // toast.success("Login successful!");
                                if (data?.data?.socialLogin) {
                                    localStorage.setItem('guest_token', data.data.socialLogin.access_token);
                                    localStorage.setItem('cz_type', data.data.socialLogin.user.user_type);
                                    localStorage.setItem('user_id', data.data.socialLogin.user.id);
                                    setName(`${data.data.socialLogin.user.first_name} ${data.data.socialLogin.user.last_name}`);
                                    getAuth(true);
                                    setEmailSignin(false);
                                    refetch();
                                }
                            }
                        }).catch(e => {
                            console.log(JSON.stringify(e));
                        })
                    }
                }
            }
        })
    }

    const handleGoogleLogin = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithPopup(provider)
                .then(function (result) {
                    // console.log("Google", result?.additionalUserInfo?.profile?.family_name)
                    //console.log("result", result);
                    socialRegisterSubmit(result, "google");

                })
                .catch(function (error) {
                    const errorMessage = error.message;
                    console.log(errorMessage);
                });
        } catch (err) {
            console.log(err);
        }
    }
    const handleFacebookLogin = async () => {
        try {
            var fbProvider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().signInWithPopup(fbProvider)
                .then(function (result) {
                    // console.log("Google", result?.additionalUserInfo?.profile?.family_name)
                    //console.log("result", result);
                    socialRegisterSubmit(result, "facebook");
                })
                .catch(function (error) {
                    const errorMessage = error.message;
                    console.log(errorMessage);
                });
        } catch (err) {
            console.log(err);
        }
    }
    const handleAppleLogin = async () => {
        try {
            var appleProvider = new firebase.auth.OAuthProvider('apple.com');
            firebase.auth().signInWithPopup(appleProvider)
                .then(function (result) {
                    console.log("result", result);
                    socialRegisterSubmit(result, "apple");
                })
                .catch(e => console.log(e));
        } catch (err) {
            console.log(err);
        }
    }

    // ----------------------login with email---------------------
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const togglePass = () => {
        setIsPasswordShown(!isPasswordShown);
    }
    const { register, formState: { errors: loginErr }, handleSubmit, control } = useForm({
        mode: "onChange",
        resolver: yupResolver(schemaLogin),
    });
    const [login] = useMutation(AUTH_LOGIN, {
        onError(err) {
            toast.error(err.graphQLErrors[0].extensions.reason);
        }
    });
    const onSubmit = data => {
        const { email, password } = data;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!(re.test(email))) {
            document.querySelector(`#email`).focus();
            document.querySelector(`#email`).scrollIntoView({ behavior: 'smooth', block: 'end' });
            toast.error("Please enter a valid Email Address!");
        } else {
            login({
                variables: {
                    username: email,
                    password: password
                }
            }).then(({ data }) => {
                if (data) {
                    if (data.login.user.user_type === 2) {
                        toast.success("Login successful!");
                        localStorage.setItem('guest_token', data.login.access_token);
                        localStorage.setItem('cz_type', data.login.user.user_type);
                        localStorage.setItem('user_id', data.login.user.id);
                        setName(`${data.login.user.first_name} ${data.login.user.last_name}`);
                        getAuth(true);
                        setEmailSignin(false);
                        refetch();
                    } else {
                        toast.info("Please login with your guest account!")
                    }
                }
            }).catch(e => {
                console.log(e);
            })
        }
    }
    // ----------------------sign up with email---------------------
    const [code, setCode] = useState("IE");
    const userLocation = GetIP();

    const [isPasswordShown1, setIsPasswordShown1] = useState(false);
    const [isPasswordShown2, setIsPasswordShown2] = useState(false)
    const togglePass1 = () => {
        setIsPasswordShown1(!isPasswordShown1)
    }
    const togglePass2 = () => {
        setIsPasswordShown2(!isPasswordShown2)
    }
    const { register: regSignUp, formState: { errors: regErr }, handleSubmit: handleSubmitRegister } = useForm({
        mode: "onChange",
        resolver: yupResolver(schemaReg),
    });
    const [phoneNumber, setPhoneNumber] = useState("");
    const [guestRegister] = useMutation(GUEST_REGISTRATION, {
        onError(err) {
            if (err.graphQLErrors[0].extensions.validation.mobile) {
                toast.warn(err.graphQLErrors[0].extensions.validation.mobile[0]);
            }
            else if (err.graphQLErrors[0].extensions.validation.email) {
                toast.warn(err.graphQLErrors[0].extensions.validation.email[0]);
            }
        }
    });

    const onEmailRegister = async data => {
        console.log("Registration")
        const { firstName, lastName, email, password } = data;
        // Generate the hash using SHA-256
        const payloadString = email + process.env.REACT_APP_SECRET_KEY;
        const hash = await crypto.subtle
        .digest("SHA-256", new TextEncoder().encode(payloadString))
        .then((buffer) => Array.from(new Uint8Array(buffer)))
        .then((hashArray) =>
            hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
        );
        guestRegister({
            variables: {first_name: firstName,
            last_name: lastName,
            mobile: phoneNumber,
            email: email,
            password: password,
            additional_mobile: "",
            client_source: "",
            display_booking: false,
            gender: "",
            dob: "",
            client_info: "",
            address: "",
            email_notification: false,
            marketing_notification: false,
            language: "", 
            hash}
        }).then(({ data }) => {
            // console.log(data.clientRegister.message);
            if (data) {
                toast.success("Registration successful!");
                setEmailSignin(true)
                setEmailSignup(false)
            }
        }).catch(e => {
            console.log(e);
        })
    }

    useEffect(() => {
        if (userLocation) {
            setCode(userLocation.country_code)
        }
    }, [userLocation])
    //--------------------Social Signup--------------------------------
    const [socialRegister] = useMutation(SOCIAL_REGISTER);

    //--------------------Forgot password--------------------------------
    const [forgotPassword] = useMutation(FORGET_PASSWORD);
    const [F_email, setF_Email] = useState("");
    const resetPassword = (e) => {
        e.preventDefault();
        if (!F_email) {
            toast.error("Email field can't be empty");
            document.querySelector(`#f_email`).focus();
            document.querySelector(`#f_email`).scrollIntoView({ behavior: 'smooth', block: 'end' });
        } else {
            forgotPassword({
                variables: {
                    email: F_email
                }
            }).then(({ data }) => {
                if (data.forgotPassword.status === 0) {
                    toast.error(data.forgotPassword.message);
                } else {
                    toast.success(data.forgotPassword.message);
                }
            })
        }
    }
    useEffect(() => {
        if (forPass) {
            document.querySelector(`#f_email`).focus();
        }
    }, [forPass])
    return (
        <div className='p-5'>
            <div className='booking-modal'>
                <Modal.Header closeButton className='px-0'>
                    <span onClick={() => { goBack(false) }} className="BackHome">
                        <img src={arrow} alt="icon" />
                    </span>
                    <Link to="/home" className="Logo" >
                        <img src={Logo} alt="Logo" />
                    </Link>
                </Modal.Header>
            </div>
            <div className="row" >
                <div className="col-md-12 mailLoginWrap">
                    {
                        baseLogin &&
                        <>
                            <div className="LoginTop">
                                <span className="title">Log in for Guests</span>
                                <p className="LoginMail socio-btn" onClick={() => { setEmailSignin(true); setBaseLogin(false); }}>
                                    <img src={Email} alt="icon" />
                                    <span>Continue With Email</span>
                                </p>
                                <p className="LoginGoogle socio-btn" onClick={handleGoogleLogin}>
                                    <img src={Google} alt="icon" />
                                    <span>Continue With Google</span>
                                </p>
                                <p className="LoginFacebook socio-btn" onClick={handleAppleLogin}>
                                    <img src={Apple} alt="icon" />
                                    <span>Continue With Apple</span>
                                </p>
                                <p className="LoginFacebook socio-btn" onClick={handleFacebookLogin}>
                                    <img src={Facebook} alt="icon" />
                                    <span>Continue With Facebook</span>
                                </p>
                                <div className="SignUp">
                                    <span>Don't have a account?</span>
                                    <button className="RegisterBtn p-2" onClick={() => { setEmailSignup(true); setBaseLogin(false); }}>
                                        Sign Up Now
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                    {
                        emailSignin &&
                        <div className='mailLoginWrap'>
                            <div className="col-md-12 LoginTop">
                                <span className="title">Log in with email</span>
                                <Form onSubmit={handleSubmit(onSubmit)} className="loginMailForm" onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        handleSubmit(onSubmit);
                                    }
                                }} autocomplete="off">
                                    <div className="formInput">
                                        <Form.Group className="mb-md-3 mb-2">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Email address" id="email" name="email" {...register('email')}
                                                error={!!loginErr.email}
                                            />
                                            <span style={{ color: 'red', fontSize: "14px" }}>{loginErr?.email?.message}</span>
                                        </Form.Group>
                                        <Form.Group className="mb-md-3 mb-2">
                                            <Form.Label>Password</Form.Label>
                                            <div className="passWrap">
                                                <Form.Control type={isPasswordShown ? "text" : "password"} placeholder="Password" name="password" {...register('password')}
                                                    error={!!loginErr.password}
                                                />
                                                <i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"} passwordIcon`}
                                                    onClick={togglePass}
                                                />
                                            </div>
                                            <span style={{ color: 'red', fontSize: "14px" }}>{loginErr?.password?.message}</span>
                                        </Form.Group>
                                        <button className="RegisterBtn p-2" onClick={() => { setForPass(true); setEmailSignin(false); }}>
                                            Forgot Password?
                                        </button>
                                    </div>
                                    <Button className="signUpBtn" type="submit">
                                        Log In
                                    </Button>
                                </Form>
                                <div className="register">
                                    <span>Don't have an account?</span>
                                    <button className="RegisterBtn p-2" onClick={() => { setEmailSignin(false); setEmailSignup(true) }}>
                                        Register Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        emailSignup &&
                        <div className='mailLoginWrap'>
                            <div className="col-md-12 LoginTop">
                                <span className="title">Sign Up to Book</span>
                                <Form onSubmit={handleSubmitRegister(onEmailRegister)} className="signUpForm" autocomplete="off">
                                    <div className="formInput">
                                        <Row className="">
                                            <Form.Group as={Col} md={12} className="pe-md-3 mb-3">
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" placeholder="Your First Name" name="firstName" {...regSignUp('firstName')}
                                                    error={!!regErr.firstName}
                                                />
                                                <span style={{ color: 'red', fontSize: "14px" }}>{regErr?.firstName?.message}</span>
                                            </Form.Group>
                                            <Form.Group as={Col} md={12} className="ps-md-3 mb-3">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Your Last Name" name="lastName" {...regSignUp('lastName')}
                                                    error={!!regErr.lastName}
                                                />
                                                <span style={{ color: 'red', fontSize: "14px" }}>{regErr?.lastName?.message}</span>
                                            </Form.Group>
                                        </Row>
                                        <Row className="">
                                            <Form.Group as={Col} md={12} className="mb-3 pe-sm-3">
                                                <Form.Label htmlFor="inlineFormInputGroup">
                                                    Mobile Number
                                                </Form.Label>
                                                <InputGroup className="Number d-block">
                                                    <PhoneInputWithCountry
                                                        countrySelectProps={{ unicodeFlags: true }}
                                                        defaultCountry={code}
                                                        name="phoneNumber"
                                                        control={control}
                                                        placeholder="Enter phone number"
                                                        international
                                                        withCountryCallingCode
                                                        value={phoneNumber}
                                                        onChange={setPhoneNumber}
                                                    // error={!!regErr.phoneNumber}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md={12} className="mb-3 ps-md-3">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="Your Email Address" name="email" {...regSignUp('email')}
                                                    error={!!regErr.email}
                                                />
                                                <span style={{ color: 'red', fontSize: "14px" }}>{regErr?.email?.message}</span>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md={12} className="pe-md-3 mb-3">
                                                <Form.Label>Password</Form.Label>
                                                <div className="passWrap1">
                                                    <Form.Control type={isPasswordShown1 ? "text" : "password"} placeholder="Create Password" name="password" {...regSignUp('password')}
                                                        error={!!regErr.password}
                                                    />
                                                    <i className={`fa ${isPasswordShown1 ? "fa-eye-slash" : "fa-eye"} passwordIcon1`}
                                                        onClick={togglePass1}
                                                    />
                                                </div>
                                                <span style={{ color: 'red', fontSize: "14px" }}>{regErr?.password?.message}</span>
                                            </Form.Group>
                                            <Form.Group as={Col} md={12} >
                                                <Form.Label>Confirm Password</Form.Label>
                                                <div className="passWrap2">
                                                    <Form.Control type={isPasswordShown2 ? "text" : "password"} placeholder="Confirm Password" name="confirm_password" {...regSignUp('confirm_password')}
                                                        error={!!regErr.confirm_password}
                                                    />
                                                    <i className={`fa ${isPasswordShown2 ? "fa-eye-slash" : "fa-eye"} passwordIcon2`}
                                                        onClick={togglePass2}
                                                    />
                                                </div>
                                                <span style={{ color: 'red', fontSize: "14px" }}>{regErr?.confirm_password?.message}</span>
                                            </Form.Group>
                                        </Row>
                                        <div className="checkCondition">
                                            <Form.Group className="mb-3" id="formGridCheckbox">
                                                <Form.Check type="checkbox" name="acceptTerms" {...regSignUp('acceptTerms')}
                                                    error={!!regErr.acceptTerms}
                                                />
                                            </Form.Group>
                                            <span>I agree to the <Link to="/">privacy policy</Link>,<Link to="/"> website terms</Link> and <Link to="/"> booking terms</Link></span>
                                        </div>
                                        <span style={{ color: 'red', fontSize: "14px" }}>{regErr?.acceptTerms?.message}</span>
                                    </div>
                                    <Button className="signUpBtn" type="submit">
                                        Sign Up
                                    </Button>
                                </Form>
                                <div className="register">
                                    <span>Already have a account?</span>
                                    <button className="RegisterBtn p-2" onClick={() => { setEmailSignup(false); setBaseLogin(true); }}>
                                        Log In Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        forPass &&
                        <div className='mailLoginWrap'>
                            <div className="col-md-12 LoginTop">
                                <span className="title">Forgot password?</span>
                                <Form className="loginMailForm" onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        resetPassword(e);
                                    }
                                }}
                                    autocomplete="off"
                                >
                                    <div className="formInput">
                                        <span>Enter your registered email address and we'll send you a secure link to setup a new password.</span>
                                        <Form.Group className="mb-md-3 mb-2">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" id="f_email" placeholder="Enter your email address" onChange={(e) => setF_Email(e.target.value)} />
                                        </Form.Group>
                                    </div>
                                    <Button className="signUpBtn resetPassBtn" type="submit" onClick={resetPassword}>
                                        Reset Password
                                    </Button>
                                </Form>
                                <div className="register">
                                    <span>Already have a account?</span>
                                    <button className="RegisterBtn p-2" onClick={() => { setBaseLogin(true); setForPass(false); }}>
                                        <img src={backarrow} alt="icon" />
                                        Back to login
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='col-md-12 mt-4'>
                    <SelectedService
                        date={date}
                        time={time}
                        cartItems={cartItems}
                        voucherAmount={totalVPrice}
                        serviceAmount={totalSPrice}
                        upfront={upfront}
                        currency={currency}
                        guestStatus={guestStatus}
                        vettingCall={videoVetting}
                    />
                </div>
            </div>
        </div>
    )
}

export default BookingLogin
