import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 5px 22px;
  }
  @media (min-width: 992px) and (max-width: 1650px) {
    flex-flow: column nowrap;
    background-color: #5c91b6;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(-0)' : 'translateX(100%)'};
    top: 65px;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 9;
    padding-left: 20px;
    li {
      color: #fff;
    }
  }
`;

const StickyMobile = ({ open,setOpen }) => {
  const guest_token = localStorage.getItem("guest_token");
  return (
    <Ul open={open}>
      <div className="back-home-main">
        <li><NavLink exact className="navItem" to="/system/guest-query/general" activeClassName="navItemActive">Contact Us</NavLink></li>
        <li><NavLink exact className="navItem" to="/system/faq" activeClassName="navItemActive">FAQ</NavLink></li>
        <li><NavLink exact className="navItem" to="/system/blog" activeClassName="navItemActive">Blog</NavLink></li>
        {/* <li><NavLink exact className="navItem" to="/system/contact-us" activeClassName="navItemActive">Contact</NavLink></li> */}
        <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/for-business">
          For Business
        </NavLink></li>
        <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/for-guest">
          For Guest
        </NavLink></li>
        <li className="dropdownWrap"><span className="navItem">30 Day Trial</span>
          <ul className="submenu">
            <li><a href="https://business.chuzeday.com/auth/registration" target="_blank" rel="noreferrer">Business</a></li>
            {/* <li><NavLink to="/signup">Guests</NavLink></li> */}
          </ul>
        </li>
        {/* <Link to="/home" className="d-block mt-5 ms-5 mb-3">Home</Link> */}
        {/* <Link to="/system/faq" className="d-block ms-5 mb-3" onClick={()=> setOpen(!open)}>FAQ</Link>
        <Link to="/system/about" className="d-block ms-5 mb-3" onClick={()=> setOpen(!open)}>About</Link>
        <Link to="/system/contact-us" className="d-block ms-5 mb-3" onClick={()=> setOpen(!open)}>Contact</Link>
        <Link to="/system/blog" className="d-block ms-5 mb-3" onClick={()=> setOpen(!open)}>Blog</Link>
        <Link to="/for-business" className="d-block ms-5 mb-3">For Business</Link>
        <Link to="/for-guest" className="d-block ms-5 mb-3">For Guest</Link>
        {
          !guest_token &&
          <Link to="/signup" className="d-block ms-5">Sign Up For Free</Link>
        } */}
      </div>
    </Ul>
  )
}

export default StickyMobile;