import React, { useEffect, useState } from 'react';

export default function ShopBottom({ bName, content, group, cancellation }) {
    const [count, setCount] = useState(0);
    const [groupCount, setGroupCount] = useState(0);
    useEffect(() => {
        var x = 0
        var y = 0
        const countServices = (num) => {
            x = x + num
            setCount(x)
        }
        const countGroupServices = (num) => {
            y = y + num
            setGroupCount(x)
        }
        if (content?.length > 0) {
            content.map((x) => (
                countServices(x?.services?.length)
            ))
            setCount(x)
        }
        if (group?.length > 0) {
            group.map((y) => (
                countGroupServices(y?.services?.length)
            ))
            setGroupCount(y)
        }

    }, [content, group])

    return (
        <>
            <section id="shopBottom" className='py-5'>
                <div className="container ShopBottomInner">
                    <div className="d-flex justify-content-around bg-light py-2">
                        {
                            bName &&
                            <h5 className='text-capitalize'>{bName}</h5>
                        }
                        <div>
                            {
                                count + groupCount === 1 &&
                                <span className='text-dark'>{count + groupCount} Service available</span>
                            }
                            {
                                count + groupCount >  1 &&
                                <span className='text-dark'>{count + groupCount} Services available</span>
                            }
                            {/* <Link to="/" className="shopBottomBtn primaryBtn">Book Now</Link> */}
                        </div>
                    </div>
                    {
                        cancellation?.length > 0 &&
                        <div className='text-center font-chzday mt-2'>
                            <span className='me-2 fw-bold'>Cancellation Policy:</span>
                            {

                                cancellation.map(item => (
                                    <small className='text-muted comma' key={item.value}>
                                        {item.value == 1 &&
                                            "50% within 48 hours"
                                        }
                                        {item.value == 2 &&
                                            "100% on the day of the booking"
                                        }
                                        {item.value == 3 &&
                                            "Notify business to accept or reject a charge against cancelled appointment"
                                        }
                                    </small>
                                ))
                            }
                        </div>
                    }
                </div>
            </section>
        </>
    )
}
