import React, { useState, useEffect, memo } from 'react';
import { Card } from 'react-bootstrap';
import { Scrollbars } from "react-custom-scrollbars-2";
import GetCurrency from "../widgets/GetCurrency";

const VoucherItem = ({ item }) => {
    const [currency, setcurrency] = useState("");
    const [disc, setDisc] = useState();
    const content = item.voucher_detail
    //----------get currency----------
    const c = GetCurrency(item?.business_info?.country)
    useEffect(() => {
        if (item?.business_info?.country && c) {
            setcurrency(c);
        }

        //------------------Discount calculate-------------------------
        if (content) {
            // console.log(content?.value, content?.retail)
            setDisc(((content?.value - content?.retail) * 100 / content?.value).toFixed(2))
        }
    }, [item?.business_info?.country, c, content]);
    return (
        <div className="col-lg-4" key={item?.id}>
            <Card className="voucher-card" style={{ ...(content.color && { background: content.color }) }}>
                <Card.Body className="text-center">
                    <div className="voucher-name">
                        {
                            content &&
                            <h4>{content.name}</h4>
                        }
                        <h4>{item?.voucher_code}</h4>
                        <div className="voucher-price">
                            <span>Redeemed</span>
                            <h6>{currency}{item?.redeemed}</h6>
                        </div>
                        <div className="voucher-price">
                            <span>Remaining balance</span>
                            <h6>{currency}{item?.remaining}</h6>
                        </div>
                        {disc > 0 ?
                            <span span className="save-price">
                                SAVE {`${disc}%`}
                            </span> :
                            <span className="unsave-price">
                            </span>
                        }
                    </div>
                    <div className="voucher-value my-4">
                        <h6>Voucher value</h6>
                        <h2>{currency}{content?.value}</h2>
                    </div>
                    <div className="voucher-desc">
                        <span>Redeem on</span>
                        <Scrollbars style={{ height: "50px", width: "100%" }}>
                            {(content?.services_included && content?.services_included.length > 0 ?
                                content?.services_included?.map((x) => (
                                    <span> <i className="far fa-check-circle mt-2 me-2"></i>{x.label}</span>
                                )) :
                                <span> <i className="far fa-check-circle mt-2 me-2"></i>all services</span>
                            )}
                        </Scrollbars>
                        <p className="p2 mb-0 voucher-valid-time">Valid for {content?.valid_for} For multiple-use</p>
                        <span>{item?.business_info?.name}</span>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default memo(VoucherItem);
