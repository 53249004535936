import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import moment from "moment";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DateTimeModal from '../SingleShop/components/ShopServices/DateTimeModal';
import { Link, useHistory } from "react-router-dom";

//API
import { MY_NOTIFICATION, NOTIFICATION_BUSINESS } from "../../../gql/Query";
import { CHANGE_APPOINTMENT, BLOCKED_GUEST_VIDEO, CANCEL_FUTURE_APPOINTMENT, SYSTEM_LOG } from "../../../gql/Mutation";
import RescheduleDateTimeModal from './RescheduleDateTimeModal';
import { imageBaseURL } from '../system/utility';
import { print } from 'graphql';
import { getDeviceInfo } from '../widgets/logUtilities';

const NotificationItem = ({ item, isCheck, setIsCheck }) => {
    const [closeDays, setCloseDays] = useState({});
    const [blockedTime, setBlockedTime] = useState({});
    const [formatServices, setFormatServices] = useState({});
    const [showReschedule, setShowReschedule] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [bData, setBData] = useState({});
    const [blockVideoCall, setBlockVideoCall] = useState(false);

    const { data, error, refetch } = useQuery(MY_NOTIFICATION);
    const [statusUpdateForAppointment] = useMutation(CHANGE_APPOINTMENT);
    const [video_consultation_booking] = useMutation(BLOCKED_GUEST_VIDEO);
    const [cancel_future_appointment] = useMutation(CANCEL_FUTURE_APPOINTMENT);

    const history = useHistory();
    //----------------business-----------------
    const [business, { data: businessData, error: businessError }] = useLazyQuery(NOTIFICATION_BUSINESS, {
        variables: {
            slug: item.business_slug
        }
    })
    const [system_log] = useMutation(SYSTEM_LOG,{
        onError(err) {
            toast.error(err.graphQLErrors[0].extensions.reason);
        }
    });
    const RESCHEDULE_APPOINTMENT_STRING = print(CHANGE_APPOINTMENT);
    const CANCEL_FUTURE_APPOINTMENT_STRING = print(CANCEL_FUTURE_APPOINTMENT);
    //--------------------status update for reschedule----------------------
    const updateStatus = (time, date, status) => {
        const token = localStorage.getItem("guest_token");
        const user_id = localStorage.getItem("user_id");
        const systemLogPayload = {
            api: RESCHEDULE_APPOINTMENT_STRING,
            type: 'reschedule-appointment-error',
            user: user_id ? JSON.stringify(user_id) : JSON.stringify(""),
            body: "",
            response: "",
            exception: "",
            source: 'web-guest',
            version: '1.1.0',
            priority: 'high',
            device: getDeviceInfo(),
        }
        const updateRequestPayload = {
             id: item.ref_id,
            service_pricing_ids: [+item?.services[0]],
            status: status,
            date: date,
            time: time,
            note: ""
        }
        if (token) {
            if (item.status === "reschedule_request") {
                statusUpdateForAppointment({
                    variables: {
                        id: item.ref_id,
                        service_pricing_ids: [+item?.services[0]],
                        status: status,
                        date: date,
                        time: time,
                        note: ""
                    }
                }).then(({ data }) => {
                    if (data?.statusUpdateForAppointment?.status === 1) {
                        toast.success(data?.statusUpdateForAppointment?.message);
                        refetch();
                        setShowReschedule(false);
                        setShowCancel(false);
                        history.push({
                            pathname: '/profile/appointments',
                            state: {
                                aptId: item.ref_id,
                                sts: (status === "Accept" || status === "Reschedule") ? "upcoming" : "past"
                            }
                        });
                    } else {
                        system_log({
                            variables: {...systemLogPayload, body: JSON.stringify(updateRequestPayload), response: JSON.stringify(data?.statusUpdateForAppointment)}
                        });
                        toast.error(data?.statusUpdateForAppointment?.message);
                    }
                }).catch((e) => {
                    system_log({
                        variables: {...systemLogPayload, body: JSON.stringify(updateRequestPayload), exception: JSON.stringify(e)}
                    });
                    console.log(e);
                    toast.error("Internal server error!");
                })
            }
            if (item.status === "blocked_warning") {
                var startDate = moment(moment.unix(item.created_at), "DD/MM/YYYY");
                var endDate = moment(date, "DD/MM/YYYY");
                var dateDiff = endDate.diff(startDate, "days");
                if (dateDiff >= 0 && dateDiff < 7) {
                    video_consultation_booking({
                        variables: {
                            video_datetime: `${date} ${time}`,
                            business_id: parseInt(bData?.business?.id)
                        }
                    }).then(({ data }) => {
                        if (data?.video_consultation_booking?.status === 1) {
                            toast.success(data?.video_consultation_booking?.message);
                            refetch();
                            setShowReschedule(false);
                            setShowCancel(false);
                            history.push({
                                pathname: '/profile/appointments',
                                state: {
                                    aptId: item.ref_id,
                                    sts: "upcoming"
                                }
                            });
                        } else {
                            toast.error(data?.video_consultation_booking?.message);
                        }
                    }).catch((e) => {
                        console.log(e);
                        toast.error("Internal server error!");
                    })
                } else {
                    toast.error("Please select any date within 7 days!")
                }
            }
        } else {
            window.location.replace("/");
        }
    }

    const getDateTime = (time, date) => {
        updateStatus(time, date, "Reschedule")
    }
    const handleClick = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
        }
    };

    //---------------Cancel all the future apts with an specific business ---------------
    const cancellAllApt = () => {
        const user_id = localStorage.getItem("user_id");
        const systemLogPayload = {
            api: CANCEL_FUTURE_APPOINTMENT_STRING,
            type: 'cancel-future-appointment-error',
            user: user_id ? JSON.stringify(user_id) : JSON.stringify(""),
            body: "",
            response: "",
            exception: "",
            source: 'web-guest',
            version: '1.1.0',
            priority: 'high',
            device: getDeviceInfo(),
        }
        if (bData?.business?.id) {
            cancel_future_appointment({
                variables: {
                    business_id: parseInt(bData?.business?.id)
                }
            }).then(({ data }) => {
                if (data?.cancel_future_appointment?.status === 1) {
                    toast.success(data?.cancel_future_appointment?.message);
                    refetch();
                    setShowCancel(false);
                    history.push({
                        pathname: '/profile/appointments',
                        state: {
                            aptId: "all",
                            sts: "past"
                        }
                    });
                } else {
                    system_log({
                        variables: {...systemLogPayload, body: JSON.stringify(bData?.business?.id), response: JSON.stringify(data?.cancel_future_appointment)}
                    });
                    toast.error(data?.cancel_future_appointment?.message);
                }
            }).catch((e) => {
                 system_log({
                    variables: {...systemLogPayload, body: JSON.stringify(bData?.business?.id), exception: JSON.stringify(e)}
                });
                console.log(e);
                toast.error("Internal server error!");
            })
        }
    }
    //-----------------Schedule Block Call----------
    const scheduleBlockCall = () => {
        setBlockVideoCall(true)
        business();
        setShowReschedule(true);
    }

    useEffect(() => {
        const closeProcess = (data) => {
            const weekDay = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
            var mapOnDays = []
            // console.log("data", data)
            data?.map((x) => mapOnDays.push(x.weekday))
            let filteredOffDay = weekDay.filter(day => !mapOnDays?.includes(day));
            const formatDay = []
            filteredOffDay?.map((x) => formatDay.push({ __typename: "BusinessWeekday", weekday: x }));
            // console.log("formatDay", formatDay);
            setCloseDays(formatDay);
        }
        if (error) {
            console.log(error)
        }
        if (businessError) {
            console.log("businessError", businessError);
        }
        if (businessData) {
            closeProcess(businessData.single_business?.business?.weekdays);
            setBlockedTime(businessData.single_business?.block_date);
            setBData(businessData.single_business);
        }
        if (item.business_slug && !item.status !== "unblocked") {
            if (item.services) {
                setFormatServices(item?.services)
            } else {
                setFormatServices({service: [], voucher: [{}] });
            }
        }
    }, [data, error, businessError, businessData, item]);
    // console.log("bData.business.id, closeDays, blockedTime, formatServices", bData?.business?.id, closeDays, blockedTime, formatServices)
    return (
        <>

            <div className='my-notification font-chzday'>
                <div className='bg-white p-3 rounded shadow-sm mb-2'>
                    <div className='d-lg-flex justify-content-between'>
                        <div className='w-75'>
                            <h3 className='d-flex'>
                                <input
                                    className='me-2'
                                    key={item.id}
                                    type="checkbox"
                                    name={item?.title}
                                    id={item.id}
                                    onChange={handleClick}
                                    checked={isCheck.includes(item.id)}
                                />
                                <span>{item?.title}</span>
                            </h3>
                            {
                                (item.status === "unblocked" && item.business_slug) ?
                                    <div>
                                        {item?.message}
                                        <Link className='ms-2' to={`/${item.business_slug}`}>
                                            Make a new booking with {item.business_slug.replaceAll('-', ' ')}
                                        </Link>
                                    </div> :
                                    (
                                        item?.target_url ?
                                            (
                                                item?.target_url === `${imageBaseURL}/profile/appointments`?
                                                    <Link
                                                        to={{
                                                            pathname: '/profile/appointments',
                                                            state: {
                                                                aptId: item.ref_id,
                                                                sts: item?.status === "refundable_deposit" ? "past" : "upcoming"
                                                            }
                                                        }}
                                                    >{item?.message}</Link> :
                                                    (
                                                        item?.target_url.includes("video-vetting") ?
                                                            <Link
                                                                to={{
                                                                    pathname: item?.target_url
                                                                }}
                                                                state={{ user_id: item.recipient_id }}
                                                                target="_blank"
                                                            >{item?.message}</Link>

                                                            : <Link to={{ pathname: item?.target_url }} target="_blank">{item?.message}</Link>
                                                    )
                                            ) :
                                            <div>{item?.message}</div>
                                    )
                            }
                        </div>
                        <div className='float-end'>
                            <div className='text-muted'><small>{moment.unix(item?.created_at).format('MMMM Do YYYY, h:mm a')}</small></div>
                        </div>
                    </div>
                    {
                        item.status === "reschedule_request" &&
                        <div className='d-flex mt-3'>
                            <button title="Accept" className="me-3 btn btn-success"
                                onClick={() => {
                                    updateStatus("", "", "Accept");
                                }}>
                                <i className="fa fa-check" />
                            </button>
                            <button
                                title="Reschedule"
                                className="me-3 btn btn-primary"
                                onClick={() => {
                                    business();
                                    setShowReschedule(true);
                                }}>
                                <i className="far fa-calendar" />
                            </button>
                            <button title="Cancel" className="me-3 btn btn-danger"
                                onClick={() => {
                                    setShowCancel(true);
                                }}
                            >
                                <i className="far fa-times-circle" />
                            </button>
                        </div>
                    }
                    {
                        item.status === "blocked_warning" &&
                        <div className='mt-2'>
                            <button
                                className='btn btn-success btn-sm me-2'
                                onClick={scheduleBlockCall}
                            >
                                Schedule Video Call
                            </button>
                            <button
                                onClick={() => {
                                    business();
                                    setShowCancel(true);
                                }}
                                className='btn btn-danger btn-sm'
                            >
                                Cancel Future Appointment
                            </button>
                        </div>
                    }
                </div>
            </div>

            {/* -------------------------cancel modal-------------------- */}
            <Modal
                show={showCancel}
                onHide={() => { setShowCancel(false) }}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="chuez-modal status-changed-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className='text-center venuItem'>
                    {
                        item.status === "reschedule_request" &&
                        <>
                            <h3>
                                {
                                    !item?.services ?
                                        "If you cancel consultation video call, associated appointment of services will be cancelled too. Do you want to cancel?" :
                                        "Are you sure you want to cancel the appointment?"
                                }
                            </h3>
                            <div className="d-flex justify-content-center mt-5">
                                <button onClick={() => { updateStatus("", "", "Cancelled") }} className='venuBtn btn btn-sm me-2'>Yes</button>
                                <button onClick={() => { setShowCancel(false) }} className='venuBtn btn btn-sm ms-2'>No</button>
                            </div>
                        </>
                    }
                    {
                        item.status === "blocked_warning" &&
                        <>
                            <h3>
                                Are you sure you want to cancel all of your future appointment with <strong>{bData?.business?.name}</strong> ?
                            </h3>
                            <div className="d-flex justify-content-center mt-5">
                                <button onClick={() => { cancellAllApt() }} className='venuBtn btn btn-sm me-2'>Yes</button>
                                <button onClick={() => { setShowCancel(false) }} className='venuBtn btn btn-sm ms-2'>No</button>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
            {/* -------------------------Reschedule modal-------------------- */}
            {
                bData?.business?.id && formatServices &&
                <Modal
                    show={showReschedule}
                    onHide={() => { setShowReschedule(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="chuez-modal status-changed-modal"
                >
                    <RescheduleDateTimeModal
                        bID={bData?.business?.id}
                        checkAuth={getDateTime}
                        closeDays={closeDays}
                        blockedTime={blockedTime}
                        formatServices={formatServices}
                        vetting={item.status === "blocked_warning" ? blockVideoCall : false}
                        notification={true}
                    />
                </Modal>
            }
        </>
    )
}

export default NotificationItem