export const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    
    // Detect device type based on user agent string
    if (/android/i.test(userAgent)) {
        return "Android Device";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS Device";
    } else if (/Windows/i.test(userAgent)) {
        return "Windows PC";
    } else if (/Macintosh/i.test(userAgent)) {
        return "Mac Device";
    } else if (/Linux/i.test(userAgent)) {
        return "Linux PC";
    } else {
        return "Unknown Device";
    }
};

export const collectBrowserData = () => {
    return {
        userAgent: navigator.userAgent, // Browser user agent
        platform: navigator.platform, // OS platform
        language: navigator.language, // Browser language
        screenResolution: `${window.screen.width}x${window.screen.height}`, // Screen resolution
        url: window.location.href, // Current page URL
        referrer: document.referrer, // Referrer URL
        isOnline: navigator.onLine, // Check if user is online   
        device: navigator.mediaDevices
    };
  };