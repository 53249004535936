import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import Logo from '../../../dist/images/logo.svg';
import { Navbar, Nav } from 'react-bootstrap';
import '../../../dist/css/style.css';
import '../../../dist/css/responsive.css';
import StickySearch from './StickySearch'
import SmHeader from './SmHeader';
import AuthHeader from "./AuthHeader";
import ScrollTop from "../widgets/ScrollTop";
import { useQuery } from '@apollo/client';
import Slider from "react-slick";
import baseUrl from "../../baseUrl";

//API
import { HOME_SLIDER } from "../../../gql/Query";
import StickyHamburger from './StickyHamburger';

const Header = () => {
    const [navbar, setNavbar] = useState(false);
    const [isMounted, setIsMounted] = useState(true);

    const guest_token = localStorage.getItem("guest_token");
    const prt_token = localStorage.getItem("token");

    const sliderRef = useRef(null);
    const iframeRefs = useRef([]);
    const isAutoplayPaused = useRef(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(undefined);
    //Search services
      const changeBackground = () => {
        if (window.scrollY >= 80) {
            // Check if the component is still mounted before updating state
            if (isMounted) {
                setNavbar(true);
            }
        } else {
            // Check if the component is still mounted before updating state
            if (isMounted) {
                setNavbar(false);
            }
        }
    };

    useEffect(() => {
        // Component is mounted
        setIsMounted(true);

        // Bind the event listener
        window.addEventListener('scroll', changeBackground);

        // Clean up function to be executed when the component is unmounted
        return () => {
            // Component is unmounted
            setIsMounted(false);

            // Unbind the event listener on cleanup
            window.removeEventListener('scroll', changeBackground);
        };
    }, [setNavbar]);


    //--------------------------Banner slider-----------------------
    const [slider, setSlider] = useState({})
    const { data, error } = useQuery(HOME_SLIDER)
    useEffect(() => {
        if (data) {
            //console.log(data)
            setSlider(data?.sliders)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    const handleSliderClick = () => {
        // Toggle between pausing and playing the autoplay
        if (sliderRef.current) {
            if (isAutoplayPaused.current) {
                sliderRef.current.slickPlay();
            } else {
                sliderRef.current.slickPause();
            }
            isAutoplayPaused.current = !isAutoplayPaused.current;
        }
    };
    const settings = {
        dots: true,
        autoplay: !isAutoplayPaused.current,
        infinite: true,
        speed: 3000,
        autoplaySpeed: 9000,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (currentSlide, next) => {
            const slideType = slider[currentSlide]?.youtube_url ? 'video' : null;
            if (slideType === 'video' && iframeRefs.current[currentSlide]) {
                const message = {
                    event: "command",
                    func: "pauseVideo",
                    args: ""
                };
                iframeRefs.current[currentSlide].contentWindow.postMessage(JSON.stringify(message), '*');
            }
        },
       afterChange:(currentSlide) => {
        const slideType = slider[currentSlide]?.youtube_url ? 'video' : null;
           if (slideType === 'video' && iframeRefs.current[currentSlide]) {
                //set for  useEffect run, when CurrentSliderIndex change
               setCurrentSlideIndex(currentSlide); // Update the current slide index
           }
    }

    };
    // useEffect(() => {
    //     if (iframeRefs.current[currentSlideIndex]) {
    //         const message = {
    //             event: "command",
    //             func: "playVideo",
    //             args: ""
    //         };
    //         iframeRefs.current[currentSlideIndex].contentWindow.postMessage(JSON.stringify(message), '*');
    //     }
    // }, [currentSlideIndex]);

    return (
        <>
            <ScrollTop />
            <header id="header" className="header-main">
                {/* ---------mobile header-------------- */}
                <div className="container-fluid navbarWrap">
                    <header className="d-block d-lg-none mobile-header" id="header">
                        <SmHeader />
                    </header>
                    <Navbar expand="lg" className={navbar ? 'navbar active' : 'navbar d-lg-flex d-none'}>
                        <NavLink to="/home" className="logo">
                            <img src={Logo} alt="Logo-img" />
                        </NavLink>
                        <StickySearch className="sticky-search" />
                        <div className="sticky_humburger">
                            <StickyHamburger />
                        </div>
                        <Nav
                            className="ms-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <ul className="primaryMenu sticky_navmenu">
                                {/* <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/">Home</NavLink></li> */}
                                <li><NavLink exact className="navItem" to="/system/guest-query/general" activeClassName="navItemActive">Contact Us</NavLink></li>
                                <li><NavLink exact className="navItem" to="/system/faq" activeClassName="navItemActive">FAQ</NavLink></li>
                                <li><NavLink exact className="navItem" to="/system/blog" activeClassName="navItemActive">Blog</NavLink></li>
                                {/* <li><NavLink exact className="navItem" to="/system/contact-us" activeClassName="navItemActive">Contact</NavLink></li> */}
                                <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/for-business">
                                    For Business
                                </NavLink></li>
                                <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/for-guest">
                                    For Guest
                                </NavLink></li>
                                <li className="dropdownWrap"><span className="navItem">30 Day Trial</span>
                                    <ul className="submenu">
                                        <li><a href="https://business.chuzeday.com/auth/registration" target="_blank" rel="noreferrer">Business</a></li>
                                        {/* <li><NavLink to="/signup">Guests</NavLink></li> */}
                                    </ul>
                                </li>
                                {!guest_token &&
                                    <li className="dropdownWrap"><span className="navItem">Log In</span>
                                        <ul className="submenu">
                                            <li><a href={prt_token ? "https://business.chuzeday.com/home" : "https://business.chuzeday.com/auth/login"} target="_blank" rel="noreferrer">Business</a></li>
                                            <li><NavLink to="/guest-login">Guests</NavLink></li>
                                        </ul>
                                    </li>
                                }
                            </ul>
                            {guest_token &&
                                <AuthHeader />
                            }
                        </Nav>
                    </Navbar>
                </div>
                {
                    slider?.length > 0 ?
                        <div className="heroSlider pb-2" onClick={handleSliderClick}>
                            <Slider {...settings} ref={sliderRef} >
                                {slider?.map((item, index) => (
                                    <div className='position-relative' key={item.bannerId}>
                                        {item?.youtube_url ? 
                                            <iframe
                                                ref={(el) => (iframeRefs.current[index] = el)}
                                                id="slider-iframe"
                                                src={`https://www.youtube.com/embed/${item?.youtube_url}?enablejsapi=1&rel=0`}
                                                title="YouTube video player"
                                                width={"100%"}
                                                height={388}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />:
                                            <>
                                                <img
                                                    className="banerimg2"
                                                    src={`${baseUrl}/${item?.image}`}
                                                    alt="BannerImage"
                                                />
                                                <div className='slider-text-inner position-absolute'>
                                                    <h1>{item?.title}</h1>
                                                </div>
                                            </>
                                        }
                                    </div>
                                ))}
                            </Slider>
                        </div> :
                        <div style={{ height: "700px" }}></div>
                }
            </header>
        </>
    );
}

export default Header;