import { useMemo } from 'react';

const useCartRawData = (cartItems, person) => {
    const formattedServices = useMemo(() => {
        const servicePriceIDList = [];
        const voucherPriceIDList = [];
        if (cartItems) {
            cartItems.forEach((x) => {
                if (x.__typename === "ServicePricing") {
                    servicePriceIDList.push({
                       service_pricing_id: parseInt(x.id),
                       date: x.date,
                       time: x.time,
                       is_group: x.is_group?? false,
                       group_type: x.group_type?? "",
                       occurrences: x.occurrences,
                    });
                } else {
                    voucherPriceIDList.push({ voucher_id: parseInt(x.id), voucher_qty: parseInt(x.qty), voucher_owner: person });
                }
            });
        }
        const totalIDList = {
            service: servicePriceIDList,
            voucher: voucherPriceIDList,
        };
        return totalIDList;
    }, [cartItems]);

    return formattedServices;
};

export default useCartRawData;
